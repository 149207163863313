/* fonts Start */
@font-face {
   font-family: 'Pretendard';
   font-weight: 100;
   font-style: normal;
   src: url('../fonts/Pretendard-Thin.otf') format("truetype");
   font-display: swap;
}
@font-face {
   font-family: 'Pretendard';
   font-weight: 200;
   font-style: normal;
   src: url('../fonts/Pretendard-ExtraLight.otf') format("truetype");
   font-display: swap;
}
@font-face {
   font-family: 'Pretendard';
   font-weight: 300;
   font-style: normal;
   src: url('../fonts/Pretendard-Light.otf') format("truetype");
   font-display: swap;
}
@font-face {
   font-family: 'Pretendard';
   font-weight: 400;
   font-style: normal;
   src: url('../fonts/Pretendard-Regular.otf') format("truetype");
   font-display: swap;
}
@font-face {
   font-family: 'Pretendard';
   font-weight: 500;
   font-style: normal;
   src: url('../fonts/Pretendard-Medium.otf') format("truetype");
   font-display: swap;
}
@font-face {
   font-family: 'Pretendard';
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/Pretendard-SemiBold.otf') format("truetype");
   font-display: swap;
}
@font-face {
   font-family: 'Pretendard';
   font-weight: 700;
   font-style: normal;
   src: url('../fonts/Pretendard-Bold.otf') format("truetype");
   font-display: swap;
}
@font-face {
   font-family: 'Pretendard';
   font-weight: 800;
   font-style: normal;
   src: url('../fonts/Pretendard-ExtraBold.otf') format("truetype");
   font-display: swap;
}
@font-face {
   font-family: 'Pretendard';
   font-weight: 900;
   font-style: normal;
   src: url('../fonts/Pretendard-Black.otf') format("truetype");
   font-display: swap;
}
/* fonts End */

/* tailwind Start */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 부정클릭 방지 아이콘 */
#bora-search-image{
   display: none;
}

img, video{
   max-width: none;
}

/* tailwind End */
:root {
   --pointColor01: #0525c9;
   --pointColor02: #0271fe;
   --pointColor03: #5db3e6;
   --subColor01: #202020;
   --subColor02: #484848;
   --subColor03: #707070;
   --subColor04: #AEAEAE;
   --subColor05: #E4E4E4;
   --subColor06: #FCFCFC;
   --subColor07: #f5f5f5;
   --subColor08: #d8d8d8;
   --subColor09: #f06000;
   --subColor10: #fbfbfe;
   --subColor11: #fbfbff;
   --subColor12: #f8f8f8;
}
html, body{
   scroll-behavior: smooth;
}

* {
   user-select: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   word-break: keep-all;
   box-sizing: border-box;
   font-family: 'Pretendard', ui-sans-serif, system-ui, sans-serif;
   padding: 0px;
   margin: 0px;
   /* color: var(--subColor01); */
   background-repeat: no-repeat;
}

body.disable-scroll{
   overflow: hidden;
}

button {
   display: block
}

b,
strong {
   font-weight: bold;
}

strong {
   display: inline-block;
}

/* img,
video {
   max-width: none;
} */

li {
   list-style: none;
}

a {
   display: block;
   text-decoration: none;
}

label {
   display: block;
}

button {
   height: auto;
}

input::-ms-clear,
input::-ms-reveal {
   display: none;
   width: 0;
   height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
   display: none;
}

.max_line1{
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}
.max_line2{
   text-overflow: ellipsis;
   overflow: hidden;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
}
.max_line3{
   text-overflow: ellipsis;
   overflow: hidden;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
}
.btn_a.type_01 {
   position: relative;
   display: flex;
   align-items: center;
   gap: 34px;
   width: fit-content;
}

.iframe_video_wrap {
   position: relative;
   padding-bottom: 56.25%;
   height: 0;
   overflow: hidden;
}

.iframe_video_wrap iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

/* #root {
   overflow: hidden;
   width: 100%;
} */

#root .btn_a.type_01.pc {
   display: flex;
}

.btn_a.type_01 .xi-long-arrow-right {
   position: relative;
   z-index: 9;
   transition: all 0.2s;
   font-size: 14px;
   color: #fff;
}

.btn_a.type_01:hover .xi-long-arrow-right {
   padding-right: 15px;
}

.btn_a.type_01::after {
   content: '';
   display: block;
   clear: both;
   position: absolute;
   top: 50%;
   right: 0px;
   transform: translateY(-50%);
   width: 40px;
   height: 40px;
   transition: all 0.25s;
   border-radius: 50%;
   background-color: var(--pointColor01);
}

.btn_a.type_01:hover::after {
   width: 100%;
   border-radius: 9999px;
}

.btn_a.type_01 .txt {
   z-index: 9;
   position: relative;
   transition: all 0.2s;
}

.btn_a.type_01:hover .txt {
   padding-left: 15px;
   color: #fff;
}

.container {
   width: 100%;
   max-width: 1280px;
   margin-left: auto;
   margin-right: auto;
}

.hide {
   display: none;
}

.hide_text {
   text-indent: -9999px;
}
.icon_loading_wrap{
   width: 100%;
   height: 400px;
   display: flex;
   justify-content: center;
   align-items: center;
}

/* Common Start */
#root .list_wrap .a_btn .icon_wrap.type_01 {
   display: flex;
}

.list_wrap .a_btn .icon_wrap.type_01 {
   justify-content: center;
   align-items: center;
   width: 33px;
   min-width: 33px;
   height: 33px;
   border-radius: 50%;
   border: 1px solid var(--subColor04);
   margin-left: auto;
}

.list_wrap .a_btn:hover .icon_wrap.type_01,
.list_wrap .a_btn:focus .icon_wrap.type_01 {
   border-color: var(--pointColor01);
}

.list_wrap .a_btn .icon_wrap.type_01 .icon {
   color: var(--subColor04);
}

.list_wrap .a_btn:hover .icon_wrap.type_01 .icon,
.list_wrap .a_btn:focus .icon_wrap.type_01 .icon {
   color: var(--pointColor01);
}

/* Common End */

/* Not Found Start */
.notfound {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 90vh;
}

.notfound .txt_wrap {
   text-align: center;
}

.notfound .txt_wrap .tit {
   line-height: 60px;
}

.notfound .txt_wrap .btn_a {
   width: fit-content;
   border-radius: 9999px;
   padding: 12px 36px;
   margin-left: auto;
   margin-right: auto;
   color: #fff;
   background-color: var(--subColor01);
}

/* Not Found End */
.type_notice .page_control_wrap.type_01 {
   margin-top: 0px;
}

.item.noitem {
   padding: 40px 0px;
}

/* Del faq */
.contact_faq .top_nav.type_01 {
   margin-bottom: 0px;
}

.contact_faq .list_wrap .list .item.question.on {
   border-bottom: 2px solid var(--subColor05);
}

.contact_faq .list_wrap .list {
   cursor: pointer;
}

.contact_faq .list_wrap .item {
   display: flex;
   align-items: center;
   gap: 40px;
   width: 100%;
   min-height: 100px;
   padding: 40px 24px;
}

.contact_faq .list_wrap .item.item_answer {
   display: none;
   background-color: var(--subColor07);
}

.contact_faq .list_wrap .item.item_answer.on {
   display: flex;
}

.contact_faq .list_wrap .item.item_answer .tit {
   color: var(--subColor01);
   background-color: #fff;
}

.contact_faq .list_wrap .item .tit {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 56px;
   min-width: 56px;
   height: 56px;
   border-radius: 50%;
   color: #fff;
   background-color: var(--pointColor01);
}

.contact_faq .list_wrap .item .txt {
   width: 100%;
   text-align: left;
}

.contact_faq .list_wrap .item .icon {
   margin-left: auto;
   color: #333;
}

.contact_faq .list_wrap .item .icon.on {
   transform: rotate(180deg);
}

.contact_faq .page_control_wrap.type_01 {
   margin-top: 0px;
}


/* del inquire */
.contact_inquire .top_head_wrap {
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
   border-bottom: 2px solid var(--subColor01);
   padding-bottom: 32px;
   margin-bottom: 40px;
}

.contact_inquire .top_head_wrap .txt .point {
   color: #ff0400;
}

.contact_inquire .inquire_from .item_01 {
   display: flex;
   gap: 40px;
}

.contact_inquire .inquire_from .input_wrap {
   width: 50%;
   margin-bottom: 32px;
}

.contact_inquire .inquire_from .input_wrap.message_wrap {
   border-bottom: 1px solid #c5c5c5;
   padding-bottom: 40px;
   margin-bottom: 40px;
}

.contact_inquire .inquire_from .input_wrap input,
.contact_inquire .inquire_from .input_wrap textarea {
   display: block;
   width: 100%;
   height: 52px;
   border: 1px solid var(--subColor08);
   border-radius: 4px;
   padding: 16px;
   margin-top: 16px;
}
.contact_inquire .inquire_from .input_wrap.message_wrap{
   width: 100%;
}
.contact_inquire .inquire_from .input_wrap.message_wrap textarea {
   width: 100%;
   height: 250px;
}

.contact_inquire .inquire_from .input_wrap .point {
   color: #a62624;
}

.contact_inquire .inquire_from .input_wrap.upload_wrap {
   display: flex;
   align-items: center;
   gap: 16px;
   padding-bottom: 32px;
   border-bottom: 1px solid #c5c5c5;
}

.contact_inquire .inquire_from .input_wrap .label_upload {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 148px;
   height: 52px;
   color: #fff;
   background-color: #7d7d7d;
}

.contact_inquire .inquire_from .input_wrap .input_upload {
   display: none;
}

.contact_inquire .privacy_wrap .info_wrap {
   padding: 32px;
   background-color: #f9f9f9;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item {
   display: flex;
   gap: 45px;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .tit,
.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .txt {
   color: #999;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .tit {
   width: 105px;
}

.contact_inquire .btn_submit {
   display: block;
   width: 870px;
   height: 70px;
   margin-left: auto;
   margin-right: auto;
   color: #fff;
   background-color: var(--subColor02);
}

.contact_inquire .privacy_agree_wrap {
   display: flex;
   align-items: center;
   gap: 8px;
}

.contact_inquire input[type="checkbox"] {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   width: 20px;
   height: 20px;
   border: 1px solid #bfbfbf;
   border-radius: 0px;
   position: relative;
}

.contact_inquire input[type="checkbox"]:checked {
   -webkit-appearance: checkbox;
   -moz-appearance: checkbox;
   appearance: checkbox;
}

/* contact Start */
.contact_us {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 980px;
}

.contact_us .item01 {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 50%;
   /* max-width: 930px; */
   height: 100%;
   background-image: url(../images/home/contact_bg.webp);
   background-size: cover;
}

.contact_us .item01 .icon_wrap {
   display: flex;
   align-items: center;
   gap: 18px;
}

.contact_us .item01 .icon_wrap .icon {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 48px;
   height: 48px;
   border-radius: 50%;
   background-color: #fff;
}

.contact_us .item02 {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   width: 50%;
   /* max-width: 990px; */
   height: 100%;
   background-color: var(--subColor10);
}

.contact_us .item02 .inquiry_from {
   width: 100%;
   max-width: 664px;
}

.contact_us .item02 .input_box_wrap {
   display: flex;
   justify-content: space-between;
   gap: 24px;
}

.contact_us .item02 .input_box_wrap .dep {
   width: 100%;
}

.contact_us .item02 .input_box_wrap .dep label .point {
   color: #a62624;
}

.contact_us .item02 .input_box_wrap .input_box {
   width: 100%;
   height: 50px;
   line-height: 50px;
   border: 1px solid #d8d8d8;
   padding: 0px 16px;
   background-color: #fff;
}

.contact_us .item02 .input_box_wrap .input_box#inquiry {
   height: 240px;
}

.contact_us .item02 .input_box_wrap .privacy_agree_wrap {
   display: flex;
   align-items: center;
   gap: 8px;
}

.contact_us .item02 .input_box_wrap .privacy_agree_wrap input[type="checkbox"] {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   width: 20px;
   height: 20px;
   border: 1px solid #bfbfbf;
   border-radius: 0px;
   position: relative;
}

.contact_us .item02 .input_box_wrap .privacy_agree_wrap input[type="checkbox"]:checked {
   -webkit-appearance: checkbox;
   -moz-appearance: checkbox;
   appearance: checkbox;
}

.contact_us .item02 .input_box_wrap .privacy_agree_wrap .view_all {
   text-decoration: underline;
   color: var(--pointColor02);
}

.contact_us .item02 .btn.type01 {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 64px;
   margin-left: auto;
   margin-right: auto;
   background-color: var(--pointColor01);
}

/* contact End */

/* PrivacyAgreement All Start */
.privacy_agreement_all_wrap {
   z-index: 9999;
   position: fixed;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.4)
}

.privacy_agreement_all_wrap .privacy_agreement_all {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 460px;
   background-color: #fff;
}

.privacy_agreement_all_wrap .privacy_agreement_all .title_wrap {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 16px 24px;
   background-color: var(--pointColor01);
}

.privacy_agreement_all_wrap .privacy_agreement_all .title_wrap * {
   color: #fff;
}

.privacy_agreement_all_wrap .text_wrap {
   padding: 24px;
}

.privacy_agreement_all_wrap .text_wrap .item {
   display: flex;
   gap: 45px;
}

.privacy_agreement_all_wrap .text_wrap .item * {
   color: #999;
}

.privacy_agreement_all_wrap .text_wrap .item .tit {
   width: 100px;
   min-width: 100px;
}

/* PrivacyAgreement All End */

/* top Start */
.top_nav.type_01 {
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
}

.top_nav.type_01 .point {
   color: var(--pointColor01);
}

.top_nav.type_01 {
   width: 100%;
   border-bottom: 2px solid var(--subColor01);
   padding-bottom: 24px;
   /* margin-bottom: 24px; */
}

.top_nav.type_01 .search_box {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 360px;
   height: 50px;
   border: 2px solid var(--subColor05);
   padding: 0px 8px 0px 24px;
   margin-left: auto;
}

.top_nav.type_01 .search_box #search {
   width: 80%;
}

.top_nav.type_01 .search_box #product_search {
   width: 85%;
   height: 100%;
}

.search_box .icon_search {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 35px;
   height: 35px;
   font-size: 19px;
   border-radius: 50%;
   color: #fff;
   background-color: var(--pointColor01);
}

/* top End */

/* res */
@media screen and (max-width:1500px) {
   .container {
      padding-left: 16px;
      padding-right: 16px;
   }

   .contact_us .item02 {
      padding-left: 24px;
      padding-right: 24px;
   }
}

/* sub category S */
#root .btn_category_wrap .btn_category.on {
   position: relative;
   font-weight: bold;
   color: var(--subColor01);
}

#root .btn_category_wrap .btn_category.on::after {
   content: '';
   display: block;
   clear: both;
   width: 100%;
   height: 3px;
   margin-top: 7px;
   background-color: var(--pointColor01);
}

/* sub category E */

@media screen and (max-width:1280px) {
   #root .btn_a.type_01 * {
      font-size: 14px;
   }

   .btn_a.type_01::after {
      width: 36px;
      height: 36px;
   }

   .search_item_wrap{
      width: 100%;
      padding-top: 40px;
   }
   #root .search_item_wrap>#par_page_select{
      width: 100%;
      height: 40px;
      font-size: 13px;
   }
   .search_from {
      width: 100%;
      padding-top: 12px;
   }
   .top_nav.type_01 .search_box{
      height: 40px;
      padding-left: 16px;
   }
   .top_nav.type_01 .search_box>input{
      font-size: 13px;
   }
   .search_box .icon_search{
      width: 28px;
      height: 28px;
      font-size: 16px;
   }

   .top_nav.type_01 {
      flex-direction: column-reverse;
      width: calc(100% - 32px);
      padding-left: 0px;
      padding-right: 0px;
      margin-bottom: 0px;
   }

   .main_img_wrap.type_01 {
      overflow-x: scroll;
      width: 100%;
      padding-bottom: 24px;
   }

   .main_img_wrap.type_01::-webkit-scrollbar {
      width: 100%;
      height: 3px;
   }

   .main_img_wrap.type_01::-webkit-scrollbar-track {
      background-color: var(--subColor05);
   }

   .main_img_wrap.type_01::-webkit-scrollbar-thumb {
      background-color: var(--pointColor03);
   }

   .main_img_wrap.type_01::-webkit-scrollbar-button {
      display: none;
   }

   .text_count {
      margin-right: auto;
   }

   .top_nav.type_01 .search_box {
      width: 100%
   }

   /* product */
   #root .product_list_wrap .product_wrap {
      grid-template-columns: 1fr;
   }

   /* main */
   .contact_us {
      display: block;
      height: auto;
   }

   .contact_us .item01,
   .contact_us .item02 {
      width: 100%;
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
   }

   .contact_us .item01 {
      padding-top: 80px;
      padding-bottom: 80px;
   }

   .contact_us .item02 {
      padding-top: 40px;
      padding-bottom: 80px;
   }

   .contact_us .item01 .icon_wrap .icon {
      width: 30px;
      height: 30px;
   }

   /* sub category S */
   #root .banner_wrap {
      .banner_text {
         width: 100%;
      }
   }

   #root .btn_category_wrap {
      overflow: hidden;
      z-index: 9;
      position: absolute;
      bottom: 0px;
      left: 0px;
      transform: translateY(calc(100% - 40px));
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      max-height: 42px;
      border-top: 1px solid var(--subColor05);
      border-bottom: 1px solid var(--subColor05);
      background-color: #fff;
   }

   #root .btn_category_wrap.on {
      max-height: 9999px;
   }

   #root .btn_category_wrap .btn_category {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 42px;
      border-right: 0px none;
      padding: 0px 16px;
   }

   #root .btn_category_wrap .btn_category.on {
      order: -1;
   }

   #root .btn_category_wrap .btn_category.on::after {
      content: none;
   }

   #root .btn_category_wrap .btn_category.on .icon_arrow {
      display: inline-block;
   }

   #root .btn_category_wrap .icon_arrow {
      display: none;
   }

   .banner_wrap {
      height: 300px;
   }

   .banner_wrap .sub_nav {
      margin-bottom: 20px;
   }

   /* sub category E */

   /* FAQ */
   .contact_faq .list_wrap .item{
      min-height: auto;
      gap: 16px;
      padding: 16px 0px;
   }
   .contact_faq .list_wrap .item .tit{
      width: 40px;
      min-width: 40px;
      height: 40px;
   }
}

@media screen and (max-width:767px) {
   .contact_us .item02 .input_box_wrap {
      display: block;
   }
}